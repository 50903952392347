@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=DM+Sans:wght@500&family=Dosis&family=Handlee&family=Inter&family=Nabla&family=Noto+Sans+Mono:wght@500&family=Reem+Kufi+Ink&family=Secular+One&display=swap');

.container {
    height: 400px;
    border: 2px solid #000;
    border-radius: 25px;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 150px 150px;
    gap: 30px 30px;
    padding: 30px;
    box-sizing: border-box;
    margin-right: 30px;
    color: #000;
    box-shadow: 13px 11px 14px -8px rgba(0,0,0,0.56);
    -webkit-box-shadow: 13px 11px 14px -8px rgba(0,0,0,0.56);
    -moz-box-shadow: 13px 11px 14px -8px rgba(0,0,0,0.56);
    background-color: rgba(255, 255, 255, 0.177);
}

.subtitle {
    /* border: 1px solid #000; */
    font-family: 'Dosis', sans-serif;
    height: 60px;
    line-height: 60px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin-left: 15px;
}

.icon {
    /* border: 1px solid #000; */
    width: 25px;
    height: inherit;
    line-height: inherit;
    /* display: inline-block; */
    margin-right: 5px;
}

.content {
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    margin-top: 10px;
    margin-left: 30px;
    font-weight: normal;
    height: 60px;
    line-height: 60px;
}

.feels_wrapper {
    border-radius: inherit;
    border: 1px solid #000;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    -moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.rain_wrapper {
    border-radius: inherit;
    border: 1px solid #000;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    -moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.humid_wrapper {
    border-radius: inherit;
    border: 1px solid #000;
    grid-row: 1 / 2;
    grid-column: 3 / 4;
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    -moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.wind_wrapper {
    border-radius: inherit;
    border: 1px solid #000;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    -moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.press_wrapper {
    border-radius: inherit;
    border: 1px solid #000;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    -moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.vis_wrapper {
    border-radius: inherit;
    border: 1px solid #000;
    grid-row: 2 / 3;
    grid-column: 3 / 4;
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    -moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.sun_wrapper {
    border-radius: inherit;
    border: 1px solid #000;
    grid-row: 1 / 3;
    grid-column: 4 / 5;
    display: grid;
    grid-template-rows: 50px 1fr 1fr;
    gap: 10px 0;
    font-family: 'Dosis', sans-serif;
    font-weight: bold;
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    -moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.title {
    line-height: 50px;
    grid-row: 1 / 2;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    margin-top: 10px;
}

.sunrise {
    /* border: 1px solid springgreen; */
    grid-row: 2 / 3;
}

.sunset {
    /* border: 1px solid springgreen; */
    grid-row: 3 / 4;
}

.sunrise,
.sunset {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sTitle {
    width: 80px;
    height: 60px;
}

.sContent {
    font-size: 1.5rem;
    line-height: 60px;
}

.sunrise .icon,
.sunset .icon {
    border: none;
    width: 65px;
    text-align: center;
}

@media (max-width: 1370px) {
    .container {
        display: block;
        padding: 10px;
    }
    .subtitle, .content {
        font-size: 1rem;
        height: 30px;
        line-height: 30px;
    }
    .feels_wrapper,
    .rain_wrapper,
    .humid_wrapper,
    .wind_wrapper,
    .press_wrapper,
    .vis_wrapper,
    .sun_wrapper {
        width: 80%;
        border: none;
        display: flex;
        padding: 7px 0;
        margin: 0 auto;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.685);
        border-radius: 0;
    }
    .sun_wrapper {
        height: 30px;
    }
    .title, .sunrise, .sContent, .sunset, .sTitle, .sContent {
        font-size: 1rem;
    }
    .sunrise .icon, .sunset .icon {
        width: 25px;
    }
    .content {
        margin: 0 15px;
    }
    .sTitle {
        width: 30px;
    }
    .title {
        line-height: 30px;
        text-align: left;
        margin: 0;
        margin-left: 15px;
    }
    .sunrise, .sunset {
        margin-left: 15px;
    }
}
@media (max-width: 1190px) {
    .subtitle, .content {
        font-size: 1rem;
    }
}
@media (max-width: 920px) {
    .sun_wrapper {
        display: none;
    }

    .feels_wrapper,
    .rain_wrapper,
    .humid_wrapper,
    .wind_wrapper,
    .press_wrapper,
    .vis_wrapper,
    .sun_wrapper {
        margin: 15px auto;
    }
}

@media (max-width: 717px) {
    .container {
        display: none;
    }
}