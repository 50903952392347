@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=DM+Sans:wght@500&family=Handlee&family=Nabla&family=Noto+Sans+Mono:wght@500&family=Reem+Kufi+Ink&family=Secular+One&display=swap');

.container {
    font-family: 'Noto Sans Mono', monospace;
    width: 400px;
    height: 400px;
    border: 2px solid #000;
    border-radius: 15px;
    display: grid;
    gap: 10px 10px;
    grid-template-columns: minmax(200px, auto) 1fr;
    color: #000;
    box-sizing: border-box;
    box-shadow: 13px 15px 23px -3px rgba(0,0,0,0.25);
    -webkit-box-shadow: 13px 15px 23px -3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 13px 15px 23px -3px rgba(0,0,0,0.25);
    background-color: rgba(255, 255, 255, 0.177);
}

.temp_wrapper {
    border: 1px solid #000;
    border-radius: 15px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: grid;
    grid-template-rows: 2fr 1.5fr;
    margin: auto 20px;
    margin-right: 10px;
    padding: 10px;
    /* Shadow here */
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
-moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.cur_temp {
    /* border: 1px solid dodgerblue; */
    font-size: 4.3rem;
    height: 100px;
    line-height: 100px;
    text-align: center;
    margin: auto;
}

.minmax_temp_wrapper {
    border-top: 3px solid #000;
    margin: auto 13px;
    padding: 15px 0;
    /* border-bottom: 2px solid #000; */
    text-align: center;
}

.min_temp {
    /* border: 1px solid slateblue; */
    margin-bottom: 5px;
}

.max_temp {
    /* border: 1px solid coral; */
}

.icon_wrapper {
    border: 1px solid #000;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 15px;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    margin: auto 20px;
    margin-left: 10px;
    height: 213px;
    /* Shadow here */
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    -moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.icon {
    /* border: 1px solid gold; */
    display: block;
    width: 130px;
    height: 130px;
    margin: auto auto;
}

.other_info_wrapper {
    margin: auto auto;
    width: 90%;
    border: 1px solid #000;
    border-radius: 30px;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    /* Shadow here */
    -webkit-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    -moz-box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
    box-shadow: 10px 10px 14px -8px rgba(35,141,173,1);
}

.desc_wrapper {
    /* border: 1px solid crimson; */
    width: 80%;
    margin: 0 auto; 
    /* position: absolute; */
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    padding-left: 30px;
    height: 60px;
    line-height: 60px;
    left: 30px;
    border-bottom: 1px solid #000;
    /* position: relative; */
}

.date_wrapper {
    width: 80%;
    margin: 0 auto; 
    padding-left: 30px;
    /* border: 1px solid crimson; */
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    height: 60px;
    line-height: 60px;
}

.desc {
    /* border: 1px solid #000; */
    margin-top: 20px;
    text-align: center;
    font-size: 1.3rem;
}

/* .desc_wrapper::after {
    content: '';
    width: 90%;
    position: absolute;
    border: 1px solid #000;
    bottom: 0;
    left: 0;
} */

