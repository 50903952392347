@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=DM+Sans:wght@500&family=Handlee&family=Nabla&family=Noto+Sans+Mono:wght@500&family=Reem+Kufi+Ink&family=Secular+One&display=swap');

/* scrollbar none  */
body {
    -ms-overflow-style: none;
    color: #000;
    /* background-image: url(../src/assets/skyBackground.jpg); */
    background: url(../src/assets/bg.jpg) no-repeat fixed center center;
    background-size: cover;
}

/* hide scroll-bar */
::-webkit-scrollbar {
    display: none;
}

.loading_container {
    display: flex;
    font-family: 'Noto Sans Mono', monospace;
    font-weight: bold;
    font-size: 4rem;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    position: fixed;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr 2fr;
    gap: 30px 50px;
    margin-top: 20px;
    margin-left: 20px;
    /* gap: ; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; */
}

.current {
    width: 400px;
    height: 350px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.next {
    font-family: 'Noto Sans Mono', monospace;
    /* border: 1px solid #000; */
    width: 400px;
    height: 500px;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: grid;
    grid-template-rows: 50px 6fr;
    overflow: hidden;
    box-shadow: 13px 63px 23px -3px rgba(0,0,0,0.25);
    -webkit-box-shadow: 13px 63px 23px -3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 13px 63px 23px -3px rgba(0,0,0,0.25);
}

.title {
    color: #000;
    /* border: 1px solid dodgerblue; */
    margin-bottom: 10px;
    font-size: 1.3rem;
    font-weight: bold;
}

.weather_wrapper {
    border: 1px solid #000;
    border-radius: 10px;
    overflow: scroll;
    box-sizing: border-box;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.177);
}

.map_container {
    height: 500px;
    border: 2px solid #000;
    /* border-radius: 15px; */
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    box-sizing: border-box;
    margin-right: 30px;
    box-shadow: 13px 11px 14px -8px rgba(0,0,0,0.25);
    -webkit-box-shadow: 13px 11px 14px -8px rgba(0,0,0,0.25);
    -moz-box-shadow: 13px 11px 14px -8px rgba(0,0,0,0.25);
}

@media (max-width: 1200px) {
    .map_container {
        display: none;
    }
}
@media (max-width: 717px) {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: 0.9fr;
        margin-left: 130px;
    }
}

@media (max-width: 660px) {
    .container {
        margin-left: 80px;
    }
}
